body {
  background: #F3EEE8;
}

.Header {
  color: #7c3f00;
  font-weight: bold;
  margin: 0px;
  padding: 10px;
  border-bottom: #7c3f00 solid 1px;
  text-align: center;
}

.Filters {
  display: flex;
  padding: 16px;
  flex-direction: row;
  gap: 8px;
  border-bottom:  #7c3f00 solid 1px;
  margin-bottom: 16px;
}

.Filters .Filter {
  flex: 1;
}

.App .react-select__placeholder {
  color: #7c3f00;
}

.App .react-select__control {
  border-color: #7c3f00;
}

.App .react-select__indicator {
  color: #7c3f00;
}

.App .react-select__indicator-separator {
  background: #7c3f00;
}

@media (max-width: 768px) {
  .Filters {
    flex-direction: column;
  }
}

.gallery-image {
  cursor: pointer;
  display: block;
  background: white;
}

.gallery-image:hover {
  opacity: 0.8;
}
